import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { useSession } from '@tootz/react-admin'

import { isAdmin, isSeller, isFinancial, isProductor } from '@/utils/userIs'

const HomePage = () => {
  const router = useRouter()
  const { data: session } = useSession()
  const { user } = session

  useEffect(() => {
    if (!user) router.push('/auth/sign-out')

    if (isAdmin(user) || isProductor(user)) router.push('/digital-products')

    if (isSeller(user) || isFinancial(user)) router.push('/orders')
  }, [])

  return <></>
}

export default HomePage
